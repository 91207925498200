import settings from "@/scripts/common/settings"
import utilities from "@/scripts/common/utilities"
import { gsap } from "gsap"
import * as menuServices from "../common/api/menu.services"

const BACK_DROP_CLASS = 'nav-backdrop'
const NAV_OPEN_CLASS = 'nav-opened'

export default class Navigation {
    constructor(container) {
        this.$el = container
        this.body = document.body
        this.$backDrop = null
        this.$sheet = null
        this.$childElems = null
        this.triggers = null
        this.triggerActiveClass = 'open'
        this.active = false
        this.tl = null
        this.delay = 0
        this.delayOverride = false
        this.isMobile = window.innerWidth < settings.breakpoints.mobile
        this.hijackScroller = false
        this.hoverOnTrigger  = false
        this.hoverOnDropdown = false
        this.hoverCloseTimer = null
        this.options = {
            ease: "power3.inOut"
        }
    }

    init() {
        menuServices.destroyMenuServices(this)
        this.setSheet()
        this.createAnim()
        this.triggerHtmlUpdater()
        this.addListeners()
        menuServices.addMenuService(this)
        
        this.$el.getNav = () => this
    }

    // Body class toggle function
    bodyClassToggle() {
        this.active ? this.body.classList.add(NAV_OPEN_CLASS + '-' + this.appendClassName) : this.body.classList.remove(NAV_OPEN_CLASS + '-' + this.appendClassName)
    }


    // Backdrop function
    createBackdrop() {
        if(this.$backDrop) this.$backDrop.remove()
        this.$backDrop = document.createElement("div")
        this.$backDrop.classList.add(BACK_DROP_CLASS)

        gsap.set(this.$backDrop, {
            left: 0,
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            autoAlpha: 0
        })

        this.body.appendChild(this.$backDrop)
        gsap.to(this.$backDrop, {
            duration: 0.4,
            autoAlpha: 1
        })

        this.$backDrop.addEventListener('click', () => menuServices.findActiveMenuService().close())
    }

    removeBackdrop() {

        this.$backDrop && gsap.to(this.$backDrop, {
            duration: 0.4,
            autoAlpha: 0,
            onComplete: () => {
                this.$backDrop && this.body.removeChild(this.$backDrop)
                this.$backDrop = null
            }
        })
    }

    // Setting sheet property
    setSheet() {
        this.$sheet = this.$el
        gsap.set(this.$sheet, { autoAlpha: 1, x: 0, y: 0, display: 'block' })

        switch (this.type) {
            case 'side-nav':
                gsap.set([this.$navItems, this.$subNavItems], { autoAlpha: 1, x: 0 })
                break
            case 'dropdown':
                gsap.set([this.$childElems], { autoAlpha: 1, y: 0 })
        }
    }

    // function to build tl
    createAnim() {
        this.createMainTl()
    }

    // Main tl preparer
    createMainTl() {
        this.tl = gsap.timeline({
            paused: true,
            onStart: () => {
                this.tweening = true
            },
            onComplete: () => {
                this.tweening = false
                if(this.hijackScroller) utilities.toggleWindowScroll(false)
            },
            onReverseComplete: () => {
                this.tweening = false
                this.bodyClassToggle()
                if(this.hijackScroller) utilities.toggleWindowScroll(true)
            }
        })
    }

    killMainTl() {
        this.tl && this.tl.kill()
    }

    // Instance specifics for sidenavs
    openSideNav() {
        return gsap.timeline()
            .from(this.$sheet, {
                duration: 0.4,
                xPercent: -100,
                display: 'none',
                ease: this.options.ease
            })
    }
    // Instance specifics for dropdowns
    openBottomSheet() {
        let tl = null
        tl = gsap.timeline()
            .from(this.$sheet, {
                duration: 0.4,
                scaleY: 0,
                transformOrigin: '50% 0%',
                display: 'none',
                ease: this.options.ease
            })
        this.$childElems &&
            tl.from(this.$childElems, {
                autoAlpha: 0,
                y: -20,
                duration: 0.8,
                ease: this.options.ease
            }, 0)

        return tl
    }

    openBottomSheetMobileRight() {
        let tl = null
        tl = gsap.timeline()
            .from(this.$sheet, {
                duration: 0.4,
                xPercent: 100,
                transformOrigin: '100% 50%',
                display: 'none',
                ease: this.options.ease
            })
        this.$childElems &&
            tl.from(this.$childElems, {
                autoAlpha: 0,
                x: 50,
                duration: 0.8,
                ease: this.options.ease
            }, 0)

        return tl
    }

    setDelayOverride(d) {
        this.delayOverride = d
    }

    // Adding delay function if already active menu exists
    addDelay() {
        this.delay = menuServices.findActiveMenuService() ? (menuServices.findActiveMenuService().tl.duration() / 2 ) : 0
        if(this.delayOverride) this.delay = 0
        //if(this.isMobile)  this.delay = 0
    }

    // Open and close main
    open() {
        this.addDelay()
        menuServices.disableAllMenuService() // reset others
        this.active = true
        this.bodyClassToggle()
        this.onOpening && this.onOpening()
        this.createBackdrop()
        //this.tl.timeScale(1).play().delay(this.delay)
        gsap.delayedCall(this.delay, () => this.tl.timeScale(1).play());
        this.triggerHtmlUpdater()
    }

    close() {
        this.active = false
        this.tl.timeScale(2).reverse()
        this.onClosing && this.onClosing()
        this.removeBackdrop()
        this.triggerHtmlUpdater()
    }

    triggerHtmlUpdater() {
        if(!this.active) {
           
            this.triggers.forEach((t) => {
                if(this.options.triggerActiveText) t.innerHTML = this.options.triggerActiveText
                if(t.dataset.passiveText) t.innerHTML = t.dataset.passiveText
                if(window.innerWidth < 768 && t.dataset.passiveTextMobile) t.innerHTML = t.dataset.passiveTextMobile
                t.classList.remove(this.triggerActiveClass)
            })
            return
        }
        this.triggers.forEach((t) => {
            if(this.options.triggerInActiveText) t.innerHTML = this.options.triggerInActiveText
            if(t.dataset.activeText) t.innerHTML = t.dataset.activeText
            if(window.innerWidth < 768 && t.dataset.activeTextMobile) t.innerHTML = t.dataset.activeTextMobile
            t.classList.add(this.triggerActiveClass)
        })
    }

    toggleOpen() {
        !this.active ? this.open() : this.close()
    }

    dropDownHeightUpdater() {
        const container = this.$el.querySelector('.dropdown-sheet__container')
        if(container) container.style.maxHeight = `${window.innerHeight - 52}px`
    }

    // Hover specifics
    onMouseOverTrigger() {
        this.hoverOnTrigger = true;
        //this.hoverOnDropdown = true;
        if (!this.active) {
            this.open(); 
        }
        if (this.hoverCloseTimer) clearTimeout(this.hoverCloseTimer);
    }

    onMouseOutTrigger() {
        this.hoverOnTrigger = false;
        this.checkCloseCondition();
    }

    onMouseOverDropdown() {
        this.hoverOnDropdown = true;
        if (this.hoverCloseTimer) clearTimeout(this.hoverCloseTimer);
    }

    onMouseOutDropdown() {
        this.hoverOnDropdown = false;
        this.checkCloseCondition();
    }
    
    checkCloseCondition() {
        if (this.hoverCloseTimer) clearTimeout(this.hoverCloseTimer)
        
        this.hoverCloseTimer = setTimeout(() => {
            if (!this.hoverOnTrigger && !this.hoverOnDropdown) {
                this.close();
            }
        }, 1000)
    }

    

    // ResizeCalc
    resizeFunc() {
        return false
    }

    // Listeners
    removeSheetListeners() {
        this.$sheet.removeEventListener('mouseenter', this.onMouseOverDropdown.bind(this));
        this.$sheet.removeEventListener('mouseleave', this.onMouseOutDropdown.bind(this));
        
    }

    addListeners() {
        const clickFunc = (e) => {
            this.setDelayOverride(this.delayOverride)
            const delayOverride = e.currentTarget.hasAttribute('data-delay-override')
            if(delayOverride) this.setDelayOverride(true)
            e.preventDefault()
            this.toggleOpen()
        }
        this.triggers.forEach(trigger => {
            const innerTrigger = trigger.querySelector('.action-link__icon-trigger')
            const triggerHook = trigger.getAttribute('data-trigger-hook');

            if (window.innerWidth > 1024 && triggerHook === 'hover') {
                trigger.addEventListener('mouseenter', this.onMouseOverTrigger.bind(this));
                trigger.addEventListener('mouseleave', this.onMouseOutTrigger.bind(this));

                this.removeSheetListeners();

                this.$sheet.addEventListener('mouseenter', this.onMouseOverDropdown.bind(this));
                this.$sheet.addEventListener('mouseleave', this.onMouseOutDropdown.bind(this));
                
                return
            }

            if(innerTrigger) {
                innerTrigger.addEventListener('click', clickFunc)
                return
            }
            trigger.addEventListener('click', clickFunc)
        })

        if(this.resizeFunc()) {
            const resizeDebounceFunc = utilities.debounce(() => this.resizeFunc(), 250)
            window.addEventListener('resize', resizeDebounceFunc)
        }

        window.addEventListener('resize', utilities.debounce(() => {
            this.isMobile = window.innerWidth < settings.breakpoints.mobile
        }), 250)
    }
}